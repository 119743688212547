<style lang="scss" scoped>
	a,
	a:visited {
		color: $grey;

		&:hover {
			color: $orange;
		}
	}

	li + li {
		margin-top: 10px;
		padding-top: 10px;
	}
</style>

<template>
	<section>
		<h1>Links</h1>
		<ul>
			<li>
				<a href="http://www.musikschulen.de/" target="_blank">Musikschulen in Deutschland</a>
			</li>
			<li>
				<a href="http://www.klavierunterricht.org/" target="_blank">Klavierunterricht</a>
			</li>
			<li>
				<a href="http://www.klaviertransporte.com/" target="_blank">Klaviertransporte</a>
			</li>
		</ul>
	</section>
</template>
